<template>
  <div class="Footer">
    <template v-if="isMobile(true)">
        <b-navbar toggleable="lg" type="dark" variant="info" class="container footerSecMob">
            <b-row>
                <b-col sm='6'>
                    <b-navbar-brand href="https://simpark.club/" target="_blank">
                        <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/SimPark_white.png"></b-img>
                    </b-navbar-brand>
                </b-col>
                <b-col sm='6'>
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item class="navItems" href="#about" right>© {{ new Date().getFullYear() }} RacePark is operated by OCS Software</b-nav-item>
                    </b-navbar-nav>
                    <b-nav-item class="navItems foot" href="#about" right>
                        <b-link href="https://www.facebook.com/profile.php?id=61572720971361" target="_blank">
                            <img class="socialIcons" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Group-82.png">
                        </b-link>
                    </b-nav-item>
                    <b-nav-item class="navItems foot" href="#about" right>
                        <b-link href="https://www.instagram.com/racepark_maresfield" target="_blank">
                            <img class="socialIcons" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Group-83.png">
                        </b-link>
                    </b-nav-item>
                </b-col>
            </b-row>
        </b-navbar>
    </template>
    <template v-else>
        <b-navbar toggleable="lg" type="dark" variant="info" class="container">
            <b-navbar-brand href="https://simpark.club/" target="_blank">
                <b-img class="logo" src="https://wp-ocssport.ocs-sport.com/simpark/wp-content/uploads/sites/5/2024/11/SimPark_white.png"></b-img>
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto">
                <b-nav-item class="navItems foot textGolf" href="#about" right>© {{ new Date().getFullYear() }} RacePark is operated by OCS Software</b-nav-item>
                <b-nav-item class="navItems foot socialIconFace" href="#about" right>
                    <b-link href="https://www.facebook.com/profile.php?id=61572720971361" target="_blank">
                        <img class="socialIcons" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Group-82.png">
                    </b-link>
                </b-nav-item>
                <b-nav-item class="navItems foot socialIconIn" href="#about" right>
                    <b-link href="https://www.instagram.com/racepark_maresfield" target="_blank">
                        <img class="socialIcons" src="https://wp-ocssport.ocs-sport.com/racepark/wp-content/uploads/sites/7/2024/11/Group-83.png">
                    </b-link>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </template>

  </div>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        isMobile() {
            if( screen.width <= 500 ) {
                return true;
            }
            else {
                return false;
            }
        },
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
/* .socialIconFace > a {
    padding-right: 0px;
} */
li.nav-item.navItems.foot.socialIconFace {
    margin-right: 0;
}
.textGolf {
    margin-top: 8px;
}
img.socialIcons {
    width: 30px;
}
::v-deep .btn-danger {
    color: #fff;
    background-color: #7ebd63;
    border-color: #7ebd63!important;
}
li.nav-item.navItems.foot > a:hover {
    color: #fff;
}
.wereConnected {
    padding-left: 30px;
    padding-left: 30px;
    padding-top: 15px;
}
.navItems {
    font-size: 12px;
}
p.FooterText {
    text-align: left;
    font-size: 12px;
}
h5.connected {
    font-size: 16px;
    text-align: left;
}
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.logo {
    width: 140px;
    height: 55px
}
.bg-info {
    background-color: #000 !important;
}
.Footer {
    background-color: #000;
    padding-top: 20px;
    padding-bottom: 20px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
@media only screen and (max-width: 1650px) {
  .TopHomeback-Image {
    background-image: url('https://wp-ocssport.ocs-sport.com/ocs-studios/wp-content/uploads/sites/2/2023/03/HeaderImage_OCS_Studios.png');
    background-repeat: no-repeat;
    background-size: contain;
    /* background-size: 100% 100%; */
    height: 75vh;
  }
}
@media only screen and (max-width: 500px) {
    .logo {
        /* padding-bottom: 20px; */
    }
    a.navbar-brand {
        margin-right: 0;
    }
    .wereConnected {
        padding-left: 0px;
        padding-left: 0px;
        padding-top: 15px;
    }
    .navItems {
        font-size: 12px;
    }
    p.FooterText {
        text-align: center;
        font-size: 12px;
    }
    h5.connected {
        font-size: 16px;
        text-align: center;
    }
}
</style>
